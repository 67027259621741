import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'

export default function Footer() {
  return (
    <footer className='footer'>
      <Link to='/policy'>
        Privacy Policy
        <img src='/images/arrow.svg' alt='arrow' />
      </Link>
      <Link to='/terms'>
        Terms of service
        <img src='/images/arrow.svg' alt='arrow' />
      </Link>
      <Link to='/service'>
        Billing Terms
        <img src='/images/arrow.svg' alt='arrow' />
      </Link>
    </footer>
  )
}
