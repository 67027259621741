import React from 'react'
import './Preview.scss'

const imgStack = [
  '/images/preview/1.jpg',
  '/images/preview/2.jpg',
  '/images/preview/3.jpg',
  '/images/preview/4.jpg',
  '/images/preview/5.jpg'
]

export default function Preview() {
  return (
    <div className='preview'>
      <h2 className='preview-title'>Preview</h2>
      <div className='preview-imgs'>
        {
          imgStack.map((item, idx) => 
            <img src={item} alt={`preview-${idx}`} />
          )
        }
      </div>
      <div className='preview-text'>
        <p>{ `Join Olive Video chat to meet new people & watch live videos!` }</p>
        <p>You are just seconds away from in unforgettable experience. Just say “Hello” to break the ice and spark a conversation!</p>
      </div>
    </div>
  )
}
