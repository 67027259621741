export default function getFBCookieString(index, fbc) {
  const cookies = document.cookie;

  return cookies
      .split('; ')
      .map(cookie => {
        if (cookie.startsWith('_fbp') && !fbc) {
            return 'fbp=' + cookie.substring(index);
        }
        if (cookie.startsWith('_fbc') && fbc) {
            return 'fbc=' + cookie.substring(index);
        }
        return null;
      })
      .filter(Boolean)
      .join('&');
}
