import React from 'react'
import Button from '../UI/Button/Button'
import './Header.scss'

export default function Header({ linkClickHandler }) {
  return (
    <header className='header'>
      <div className='header-content'>
        <img src='/images/app-icon.png' alt='app-icon'/>
        <div className='header-text'>
          <h1 className='title'>{ `Olive - Live Video\nChat App` }</h1>
          <h3 className='subtitle'>{ `Match & Make New Friends` }</h3>
          <div className='btn-wrap'>
            <Button cls={['btn']} text='Get' handleClick={linkClickHandler} />
          </div>
        </div>
      </div>
      <div></div>
    </header>
  )
}
