import React from 'react'
import AppPrivacy from '../AppPrivacy/AppPrivacy'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Preview from '../Preview/Preview'
import Rating from '../Rating/Rating'
import StoreInfo from '../StoreInfo/StoreInfo'
import './StoreScreen.scss'

export default function StoreScreen({ linkClickHandler }) {
  return (
    <div className='StoreScreen'>
      <Header  linkClickHandler={linkClickHandler} />
      <StoreInfo />
      <Preview />
      <Rating />
      <AppPrivacy />
      <Footer />
    </div>
  )
}
