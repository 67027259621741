import React, { useEffect, useState }  from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import gtag from 'ga-gtag'
import { v4 as uuidv4 } from 'uuid';
import BillingTerms from './components/BillingTerms/BillingTerms'
import Policy from './components/Policy/Policy'
import ServiceTerms from './components/ServiceTerms/ServiceTerms'
import Wrapper from './layout/Wrapper'
import exportedObj from './helpers/generatedLinkString';
import StoreScreen from './components/StoreScreen/StoreScreen'


function App () {
    const [link, setLink] = useState(exportedObj.urlString)
    const [userFbId] = useState(uuidv4())
    const getUserDomainData = async () => {
    	const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace', {
        	method: 'GET'
    	})
    	let data = await response.text()
    	data = data.trim().split('\n').reduce(function(obj, pair) {
        	pair = pair.split('=');
        	obj[pair[0]] = pair[1]
        	return obj
    	}, {})
    	return data
    }

    const linkClickHandler = async () => {
        gtag('event', 'click_install', {
            'event_category' : 'install_btn',
            'event_label' : 'click'
        })
	window.fbq('init', 'it7gogoct3t7pyn0gmgc8k16rws5vr', {
            'extern_id': userFbId,
            'external_id': userFbId
        });
        const timeOut = setTimeout(() => {
            window.open(link, '_blank')
            clearTimeout(timeOut)
        }, 600)
    }

    const sendDomainData = async () => {
        try {
            const { loc: country, ip } = await getUserDomainData()
            const { hostname, href } = window.location
            const parsedLink = href.slice(href.indexOf('?') + 1)
            const requestBody = JSON.stringify({
                referrer: parsedLink,
                domain: hostname,
                ip,
                country: country
            })
            await fetch('https://prlivechat.com:3000/landingVisit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestBody
            })
	    setLink(link +  `&userIp=${ip}&browser=${encodeURI(window.navigator.userAgent)}`)
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
       sendDomainData() 
    }, [])

    return (
        <Wrapper>
            <Router>
               <Switch>
                    <Route path="/" exact>
                        <StoreScreen linkClickHandler={linkClickHandler} />
                    </Route>
                    <Route path="/policy">
                        <Policy/>
                    </Route>
                    <Route path="/terms">
                        <BillingTerms />
                    </Route>
                    <Route path="/service">
                        <ServiceTerms/>
                    </Route>
                    <Redirect to="/" />
                </Switch>
            </Router>
        </Wrapper>
    )
}

export default App
