import OneLinkUrlGenerator from './AppsFlyerLinkGenerator';
import getFBCookieString from './getFBCookie'

let urlString

(function(){
  window.AF = Object.assign((window.AF || {}),{OneLinkUrlGenerator: OneLinkUrlGenerator});
})();

const linkGenerator = new window.AF.OneLinkUrlGenerator({
  oneLinkURL: "https://olivevideochat.onelink.me/F0qB", 
  pidKeysList: ['af_pid', 'pid', 'utm_source'], 
  campaignKeysList: ['af_c', 'c', "campaign.name", 'utm_campaign'], 
  gclIdParam: "af_sub2", 
  campaignStaticValue: 'defaultstorefb', 
  pidStaticValue: 'defaultstorefb', 
  fbp: 'af_sub3'
}); 
linkGenerator.setChannel("af_channel", "defaultstorefb"); 
linkGenerator.setAdset("af_adset", "defaultstorefb"); 
linkGenerator.setAd("af_ad", "defaultstorefb"); 
linkGenerator.setCustomParameter("fbclid", "af_sub1", "defaultstorefb"); 
linkGenerator.setCustomParameter("af_c_id", "af_c_id","defaultstorefb");
linkGenerator.setCustomParameter("af_adset_id", "af_adset_id", "defaultstorefb"); 
linkGenerator.setCustomParameter("af_ad_id", "af_ad_id","defaultstorefb"); 
linkGenerator.setCustomParameter("keyword", "af_keywords", "defaultstorefb");

urlString = linkGenerator.generateUrl();

if (getFBCookieString(5, true)) {
  urlString += `&${getFBCookieString(5, false)}`  
  urlString += `&${getFBCookieString(5, true)}`
} else {
  const afSub1Start = urlString.indexOf('af_sub1')
  const newString = `${urlString.substr(afSub1Start + 7)}`
  const afSub1End = newString.indexOf('&')
  urlString += `&fbc${newString.substr(0, afSub1End)}`
}

const exportedObj = {
  urlString
}

export default exportedObj
