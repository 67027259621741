import React from 'react'
import './AppPrivacy.scss'

const imgStack = [
  '/images/privacy/privacy-1.svg',
  '/images/privacy/privacy-2.svg',
  '/images/privacy/privacy-3.svg',
]


const description = [
  { title: 'Languages', description: 'English, Arabic, Danish, Dutch, French, German, Hebrew, Hindi, Hungarian, Indonesian, Italian, Japanese, Norwegian Bokmål, Polish, Portuguese, Russian, Simplified Chinese, Spanish, Swedish, Thai, Turkish, Vietnamese' },
  { title: 'Age Rating', description: `17+` },
  { title: 'Size', description: '183.7 MB' },
  { title: 'Copyright', description: '© 2019 Accordmobi LLC' }
]

export default function AppPrivacy() {
  return (
    <div className='app-privacy'>
      <h2 className='title'>App Privacy</h2>
      <p className='description'>The developer, Accordmobi LLC, indicated that the app’s privacy practices may include handling of data as described below.</p>
      {
        imgStack.map((item, idx) => 
          <img className='privacy-img' src={item} alt={`privacy-${idx}`} />
        )
      }
      {
        description.map(({ title, description }) => 
        <div className='privacy-data'>
          <span className='title'>{ title }</span>
          <p  className='description'>{ description }</p>
        </div>
        )
      }
    </div>
  )
}
