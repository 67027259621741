import React from 'react'
import './StoreInfo.scss'

const imgStack = [
  '/images/store-info/1.svg',
  '/images/store-info/2.svg',
  '/images/store-info/3.svg'
]
 
export default function StoreInfo() {
  return (
    <div className='storeinfo'>
      <div className='storeinfo__content'>
        {
          imgStack.map((item, idx) => 
            <img src={item} alt={idx} />
          )
        }
      </div>
    </div>
  )
}
