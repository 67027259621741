import React from 'react'
import './Rating.scss'

export default function Rating() {
  return (
    <div className='rating'>
      <div className='rating-img-wrap'>
        <img src='/images/rating.svg' alt='rating' />
        <img src='/images/review.svg' alt='review' />
      </div>
    </div>
  )
}
