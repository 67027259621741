import React from 'react'
import './Button.scss'

export default function Button({ text, cls, handleClick }) {
    return (
        <button className={cls.join(' ')} onClick={handleClick}>
            {text}
        </button>
    )
}
