import React, { useEffect } from 'react'
import './BillingTerms.scss'

export default function BillingTerms() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className="container-2">
            <h1>Subscription Billing Terms</h1>
            <div>
                1. Payment will be charged to your Application Store (iTunes & App Store or Google Play) Account at
                confirmation of purchase.
            </div>
            <div>
                2. Subscription automatically renews unless auto-renew is turned off at least 24-before the end of the
                current period.
            </div>
            <div>
                3. Account will be charged for renewal within 24-hours prior to the end of the current period, and
                identify the cost of the renewal.
            </div>
            <div>
                4. Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user’s
                Application Store (iTunes & App Store or Google Play) Account Settings after purchase.
            </div>
            <div>
                5. This price is for United States customers. Pricing in other may very and actual charges may be
                converted to your local currency depending on the country of residence.
            </div>
            <div>
                6. If you do not wish to start your Olive Video Chat Premium subscription, you can still continue to
                enjoy Olive Video Chat for free.
            </div>
        </div>
    )
}
